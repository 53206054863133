import React from 'react'
import { graphql } from 'gatsby'

// Layout
import Layout from "../../components/layout/index"
import SEO from "../../components/seo"

// Components
import Header from './header'
import Intro from './intro/index'
import List from './list/index'
import Features from './features/index'

export default ({ data: {
    wordpressAcfService: {
        acf: {
            title,
            excerpt,
            subtitle,
            description,
            service_list,
            feature_list
        }
    }
}}) => {
    return (
        <Layout>
            <SEO title={title}/>
            <Header 
                title={title}
                description={excerpt}
            />
            <Intro
                title={subtitle}
                description={description}
            />
            <List
                data={service_list}
                title={title}
            />
            <Features
                title={`Why Choose us`}
                data={feature_list}
            />
        </Layout>
    )
}

export const serviceQuery = graphql`
    query singleService ($id: Int!) {
        wordpressAcfService(wordpress_id: {eq: $id}) {
            acf {
                title
                subtitle
                description
                excerpt
                feature_list {
                    title
                    description
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 468, quality: 75) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
                service_list {
                    title
                }
            }
        }
    }
`