import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../../../components/container'
import Item from './item'

export default ({ data, title }) => {
    return (
        <Section>
            <Container
                sm={`
                    display:grid;
                    background-color:#fff;
                    box-shadow: 0 4px 11px 0 rgba(37,44,97,.15), 0 1px 3px 0 rgba(93,100,148,.2);
                    padding:1rem;
                    margin-top:-1.5rem;
                    border-radius: 0.25rem;
                    grid-template-columns:1fr;
                    grid-row-gap:1rem;
                `}
                lg={`
                    grid-row-gap:3rem;
                    grid-column-gap:1.5rem;
                    padding:3rem;
                    margin-top:-7rem;
                    align-items:center;
                    grid-template-columns:1fr minmax(0, 3fr);
                `}
            >
            <Title>{`Our ${title} Services`}</Title>
            <List>
                {data.map(({ title, description }, index) => (
                    <Item
                        key={index}
                        title={title}
                        description={description}
                    />
                ))}
            </List>
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`
    overflow:visible;
`
const Title = styled.h2`
    font-size:1.5rem;
    line-height:1.15;
    text-align:center;
    @media (min-width:992px) {
        font-size:2.25rem;
        text-align:left;
    }
`
const List = styled.ul`
    display:grid;
    margin:0;
    grid-template-columns:repeat(2, minmax(0, 1fr));
    grid-column-gap:1rem;
    grid-row-gap:1rem;
    width:100%;
    @media (min-width:992px) {
        grid-template-columns:repeat(4, minmax(0, 1fr));
        grid-column-gap:1.5rem;
        grid-row-gap:1.5rem;
        align-items: start;
    }
`