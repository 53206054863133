import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

export default ({
    title,
}) => {
    return (
        <Wrapper>
            <Svg viewBox="0 0 27 20">
                <path d="M24.42.45,9,16.35,2.58,9.73A1.5,1.5,0,0,0,.42,11.82l7.5,7.72a1.5,1.5,0,0,0,2.16,0l16.5-17A1.5,1.5,0,0,0,24.42.45Z"></path>
            </Svg>
            <Title>{title}</Title>
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled.li`
    display:grid;
    grid-template-columns:1.5rem minMax(0, 1fr);
    grid-column-gap:0.5rem;
    align-items:center;
    @media (min-width:992px) {
        grid-template-columns:2rem minMax(0, 1fr);
    }
`
const Svg = styled.svg`
    background-color:${props => lighten(0.35, props.theme.color.blue.primary)};
    height:1.5rem;
    width:1.5rem;
    padding:0.5rem;
    border-radius:50%;
    fill:${props => props.theme.color.blue.primary};
    @media (min-width:992px) {
        height:2rem;
        width:2rem;
        padding:0.5rem;
    }
`
const Title = styled.p`
    font-size:0.85rem;
    line-height:1;
    @media (min-width:992px) {
        font-size:1rem;
    }
`