import { graphql, useStaticQuery } from 'gatsby'

export const usePlaceholderBg = () => {
    const { file } = useStaticQuery(graphql`
        query usePlaceholderBgQuery {
            file(relativeDirectory: {eq: "placeholders"}, name: {eq: "bg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000, maxHeight: 650, quality: 65) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return file.childImageSharp.fluid
}