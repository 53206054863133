import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

// Hooks
import { usePlaceholderBg } from '../../../hooks/placeholders/usePlaceholderBg'

// Components
import Container from '../../../components/container'

export default ({
    data
}) => {
    return (
        <Section>
            <Container
                sm={`
                    display:grid;
                    grid-template-columns:
                        minMax(0, 1fr)
                    ;
                    grid-row-gap: 3rem;
                    padding-top:3rem;
                    padding-bottom:3rem;
                `}
                lg={`
                    grid-row-gap: 4.5rem;
                    padding-top:4.5rem;
                    padding-bottom:4.5rem;
                `}
            >
                {data.map(({
                    title,
                    description,
                    image: {
                        localFile: {
                            childImageSharp: {
                                fluid
                            }
                        }
                    }
                }, index) => (
                    <Item key={index}>
                        <Content>
                            <Title>{title}</Title>
                            <Description>{description}</Description>
                        </Content>
                        <Image fluid={fluid ? fluid : usePlaceholderBg()}/>
                    </Item>
                ))}
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.div`
    background-image: linear-gradient(rgb(255, 255, 255), rgb(245, 245, 250));
`
const Item = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    flex-direction:column-reverse;
    @media (min-width:992px) {
        flex-direction:row;
        &:nth-child(even) {
            flex-direction:row-reverse;
        }
    }
`
const Content = styled.div`
    display:grid;
    grid-template-columns: minMax(0, 1fr);
    grid-row-gap: 1.5rem;
    @media (min-width: 992px) {
        align-content:center;
        width:calc(60% - 3rem);
    }
`
const Title = styled.h2`
    color:${props => props.theme.color.blue.dark};
    font-size:1.5rem;
    @media (min-width:992px) {
        font-size:2rem;
    }
`
const Description = styled.p`
    font-size:0.85rem;
    @media (min-width:992px) {
        font-size:1rem;
    }
`
const ImageContainer = styled.div`
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    box-shadow: rgba(37,44,97,0.15) 0px 5px 15px 0px, rgba(93,100,148,0.2) 0px 2px 4px 0px;
    border-radius:0.5rem;
    width:100%;
    margin:0 0 1.5rem 0;
    max-height:400px;
    @media(min-width: 992px) {
        width: 40%;
        margin:0;
    }
`
const Image = styled(Img)`
    box-shadow: rgba(37,44,97,0.15) 0px 5px 15px 0px, rgba(93,100,148,0.2) 0px 2px 4px 0px;
    border-radius:0.5rem;
    width:100%;
    margin:0 0 1.5rem 0;
    max-height:300px;
    @media(min-width: 992px) {
        width: 40%;
        margin:0;
    }
`