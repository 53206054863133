import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

// Components
import Container from '../../../components/container'

export default ({
    title,
    description
}) => {
    return (
        <Section id="service-item">
            <Container
                sm={`
                    padding-top:3rem;
                    padding-bottom:3rem;
                    text-align:center;
                `}
                lg={`
                    padding-top:4.5rem;
                    padding-bottom:11.5rem;
                `}
            >
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`
    ${props => `
        background-image: radial-gradient( circle at 50% 0,
            ${darken(0.25, props.theme.color.blue.primary)},
            ${darken(0.4, props.theme.color.blue.primary)}
        );
    `}
    
`
const Title = styled.h2`
    color:#fff;
    font-size:1.5rem;
    margin-bottom:1rem;
    @media (min-width:992px) {
        font-size:2rem;
    }
`
const Description = styled.p`
    color: #fff;
    margin: 0 auto;
    line-height: 1.75;
    font-size:0.85rem;
    @media (min-width:992px) {
        font-size:1rem;
    }
`